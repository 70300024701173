<template>
  <div>
    <portal to="v-main">
      <EditDialog v-model="showEditDialog" :id="id" :api="url" v-if="editObject" />
      <s-quickOpenDoc ref="quickOpenDoc" />
    </portal>
    <div v-if="!loading && getAccess('object.showPlanning')">
      <BalanceInvestor
        v-if="data.is_investor == 1"
        :id="id"
        :expensesCalc="expensesCalc"
        :data="data"
        @onBalance="setBalance"
        @onEdit="showEditDialog = true"
        @tableClick="onDetailTableClick($event)"
      />
      <BalanceClient v-else :id="id" :expensesCalc="expensesCalc" :data="data" @onBalance="setBalance" @tableClick="onDetailTableClick($event)" />
    </div>
    <v-row v-if="!loading">
      <v-col cols="12" v-if="!loading">
        <Statictics v-if="!loading" :id="id" :expensesCalc="expensesCalc" :data="data" @tableClick="onDetailTableClick($event)" />
      </v-col>
      <v-col cols="12" v-if="$root.profile.role == 1000">
        <material-card color="third">
          <template v-slot:heading>
            <div class="px-3 py-1">Balance</div>
            <btn-excel :data="balanceData" :model="modelAcc" />
          </template>
          <v-card-text style="height: 300px; overflow: auto" class="pa-0">
            <a-table-f-data2
              ref="table"
              :dataTable="getData()"
              :model="[{ name: 'type', type: 'variant', sortable: true }, { name: 'operation_id', title: 'Операция', type: 'string' }, ...modelAcc]"
              :useQuery="false"
              :footer="balanceFooter"
              :defaults="{
                sort: {},
              }"
            >
            </a-table-f-data2>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>

    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </div>
</template>

<script>
import { getAccess, autoHeightBlock, popupMenu, libObjectPlanning } from "@/components/mixings";
export default {
  mixins: [getAccess, autoHeightBlock, popupMenu, libObjectPlanning],
  components: {
    Statictics: () => import("./planningStatistic"),
    BalanceInvestor: () => import("./planningInvestor"),
    BalanceClient: () => import("./planningClient"),
    EditDialog: () => import("./../../dialogs/objectPlanningDialog"),
  },
  props: {
    idShow: Number,
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      loading: true,
      showEditDialog: false,
      showLegend: true,
      title: "",
      data: {},

      modelAcc: this.$store.getters["config/get"].models.accRecords.list,
      url: "/mechti/objects",
      tab: 0,
      tab_photo: 0,
      balanceData: [],
      balanceFooter: {},
      balanceCalc: {},
      documentData: [],
      clientHeight: 0,
      curValueDetail: null,
      detailData: [],
      detailModel: [],
      detailFooter: {},
      expensesCalc: null,
    };
  },
  created() {
    this.loading = true;
    this.id = this.idShow || Number(this.$route.params.id);
    this.permit = this.getAccess("menu.objects");
    this.fitchData();
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.loading = true;
        this.fitchData();
      }
    },
    curValueDetail() {
      this.getValueDetail();
    },
  },
  computed: {
    model() {
      return this.$store.getters["config/get"].models.objects.form;
    },
    editObject() {
      return this.getAccess("object.edit", {
        id: this.id,
        access: this.data.object_access,
        data: this.data,
      });
    },
    showObject() {
      return this.getAccess("object.edit", {
        id: this.id,
        access: this.data.object_access,
        data: this.data,
        inRole: -1,
      });
    },
  },
  methods: {
    onDetailTableClick(d) {
      if (d.field?.isMenu) {
        if (["base_doc_name", "name_doc"].includes(d.field.name)) {
          const menuItems = [
            {
              name: d.field.name,
              title: "Открыть документ",
              action: async () => {
                this.$refs.quickOpenDoc.openDocument(d.row.base_name, d.row.base_id, d.row);
              },
            },
          ];
          this.showPopupMenu(d.field.name, d.row, d.event, { menuItems });
        } else this.showPopupMenu(d.field.name, d.row, d.event);
      }
    },

    setBalance(b) {
      this.documentData = b.document;
      this.balanceData = b.data;
      this.balanceFooter = b.footer;
      this.balanceCalc = b.calc;
      console.log("set balance", this.id, b.calc);
      this.balanceSave(this.id, b.calc);
      this.calcExpenses();
    },
    async balanceSave(id, b) {
      if (!id) return;
      try {
        let d = { id, balance_calc: { date: new Date().date, calc: b } };
        //console.log("post", d);
        let response = await this.$axios.post(this.url, d);
        if (response.data.status !== "ok") {
          console.log("error", response);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    getValue(n) {
      let res = this.data;
      n.split(".").forEach((p) => {
        res = res[p];
      });
      return res;
    },
    getData() {
      //this.getBalanceTable();
      return this.balanceData;
    },

    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
        let progress = await this.getObjectProgress(this.data.id);
        this.data.progress = progress;
        this.fillData(this.model);
      }
      this.afterFetchData();
      //      this.getBalanceTable();
      //this.calcExpenses();

      this.loading = false;
    },
    fillData(model) {
      for (let el of model) {
        if (el?.calculated == true) {
          if (el?.formula) {
            let f = el.formula;
            Object.defineProperty(this.data, el.name, {
              get: function () {
                let res = 0;
                let func;
                if (typeof f == "function") func = f;
                if (typeof f == "string") func = eval(f);
                res = func(this);
                return res;
              },
            });
          }
        }
      }
    },

    formatNumber(row, col, value, index) {
      return isNaN(value) ? value : value.toLocaleString("ru-Ru");
    },

    async calcExpenses() {
      let calc = {};
      let resp = null;
      let q;
      try {
        resp = await this.$axios.post("/report_sql", { name: "planning_getGoodsAndServices", params: { id: this.data.id } });
        if (resp.data.status !== "ok") {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка сбора данных",
          });
          return;
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка сбора данных",
        });
        return;
      }

      let template = JSON.stringify({
        table: null,
        id: null,
        category_id: null,
        category_name: null,
        name: null,
        price_plan: null,
        price_real: null,
      });
      //   return;
      resp.data.data.forEach((r) => {
        let row = JSON.parse(JSON.stringify(r));
        let tmp;
        let g_name = `${r.table}`;
        if (!calc["l_0"]) calc["l_0"] = {};
        let c = calc["l_0"];
        row.percent =
          this.documentData.find((d) => {
            // return d.doc == `order_${row.table}` && d.id == row.base_id;
            return d.base_name == row.base_name && d.id == row.base_id;
          })?.percent || 0;
        row.payment = (row.price_real / 100) * row.percent;
        //console.log("row", row.table, row.base_id, row.payment, this.documentData);
        if (!c[g_name]) {
          c[g_name] = JSON.parse(template);
          c[g_name].hasChildren = true;
          c[g_name].children_name = `${r.table}-category`;
          c[g_name].name = r.table == "goods" ? "Все товары" : "Все услуги";
          c[g_name].id = g_name;
          c[g_name].level = "l_1";
          c[g_name].payment = 0;
        }
        c[g_name].price_real += r.price_real;
        c[g_name].price_plan += r.price_plan;
        c[g_name].payment += row.payment || 0;
        c[g_name].percent = (c[g_name].payment / c[g_name].price_real) * 100;

        g_name = `${r.table}-category-${r.category_id}`;
        if (!calc["l_1"]) calc["l_1"] = {};
        let c2 = calc["l_1"];
        if (!c2[g_name]) {
          c2[g_name] = JSON.parse(template);
          c2[g_name].hasChildren = true;
          c2[g_name].parent_name = `${r.table}`;
          c2[g_name].name = r.category_name;
          c2[g_name].group_name = g_name;
          c2[g_name].id = g_name;
          c2[g_name].children_name = `${r.table}-category-${r.category_id}`;
          c2[g_name].level = "l_2";
          c2[g_name].payment = 0;
        }
        c2[g_name].price_real += r.price_real;
        c2[g_name].price_plan += r.price_plan;
        c2[g_name].payment += row.payment;
        c2[g_name].percent = (c2[g_name].payment / c2[g_name].price_real) * 100;

        g_name = `${r.table}-category-${r.category_id}-detail`;
        if (!calc["l_2"]) calc["l_2"] = {};
        let c3 = calc["l_2"];
        if (!c3[g_name]) {
          c3[g_name] = [];
        }
        c3[g_name] = [...c3[g_name], row];
      });
      q = `select sum(price) price_total from construction_goods where object_id=${this.data.id}`;
      resp = await this.$axios.post("/accounting/records/report-sql", { q });
      if (resp.data.status == "ok") {
        if (calc.l_0?.["goods"]) calc.l_0["goods"]["total_plan"] = resp.data.data[0].price_total;
      }
      //   this.expensesData = [calc.l_0["goods"], calc.l_0["services"]];
      //  return;
      this.expensesCalc = calc;
    },

    updateData() {
      this.fitchData();
    },

    afterFetchData() {},
  },
};
</script>

<style lang="sass">
.el-table .cell
  word-break: break-word
.showEmptyText
  position: absolute
  width: 100%
  height: 100%

td.active
  background-color: rgba(75, 192, 192, 0.4) !important
.legend-chk
  margin: 0 !important
  padding: 0 !important

.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
